import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import { get } from "lodash";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import { List, Button } from "antd";

import Pages from "../../components/Pages";
import { ContentBlock } from "../../components/Blocks";
import { Breadcrumbs } from "../../components/Layout";
import { AudioPlayer } from "../../components/Media";
import { Share } from "../../widgets";
import { makeUrl, removeEmptyKeys, removePreloader } from "../../helpers";
import { showMore } from "../../constants";
import EmptyData from "../../components/EmptyData";
import { useThemeContext } from "../../components/Layout/ThemeContext";
import { RADIO_RECORD_LIST, RADIO_RECORD_LIST_MAX_UPDATE } from "../../queries/queries.graphql";

export const query = graphql`
	query radioRecordList {
		hasura {
			...RadioRecordList
		}
	}
`;

export default function RedioRecords({ data }) {
	const { theme } = useThemeContext();
	const limit = 5;

	const [radioRecords, setRadioRecords] = useState(get(data, "hasura.v_media_radio_records", []));
	const [offset, setOffset] = useState(limit);
	const isAllRecordsLoaded = get(data, "hasura.v_media_radio_records_aggregate.aggregate.count", 0) === radioRecords.length;

	const maxUpdated = new Date(get(data, "hasura.v_media_radio_records_aggregate.aggregate.max.updated_at", new Date()));

	const radioRecordsOptions = {
		displayName: "RadioRecordQuery",
		fetchPolicy: "cache-and-network",
		ssr: false,
	};

	const { loading: maxUpdateLoading, data: maxUpdateData, error: maxUpdateError } = useQuery(
		RADIO_RECORD_LIST_MAX_UPDATE,
		radioRecordsOptions,
	);

	const [loadRadioRecords, { called: loadRadioRecordCalled, loading: loadRadioRecordLoading, error: loadRadioRecordError, data: loadRadioRecordData }] = useLazyQuery(
		RADIO_RECORD_LIST,
		{
			variables: {
				limit,
				offset: 0,
			},
			...radioRecordsOptions,
		},
	);

	const [loadMore, { called: loadMoreCalled, loading: loadMoreLoading, error: loadMoreError, data: loadMoreData }] = useLazyQuery(
		RADIO_RECORD_LIST,
		{
			variables: {
				limit,
				offset,
			},
			...radioRecordsOptions,
		},
	);

	useEffect(() => {
		const currentMaxUpdated = new Date(get(maxUpdateData, "v_media_radio_records_aggregate.aggregate.max.updated_at", new Date()));

		if (maxUpdateData && !maxUpdateError) {
			if (+currentMaxUpdated !== +maxUpdated) {
				loadRadioRecords();
			} else {
				removePreloader();
			}
		} else if (maxUpdateError) {
			console.error("Invalid load RadiorecordsMaxUpdate", { maxUpdateError });
			removePreloader();
		}
	}, [maxUpdateLoading]);

	useEffect(() => {
		if (loadRadioRecordCalled && !loadRadioRecordError && !loadRadioRecordLoading) {
			setRadioRecords(get(loadRadioRecordData, "v_media_radio_records", []));
			setOffset(get(loadRadioRecordData, "v_media_radio_records", []).length < limit ? null : offset + limit);
		} else if (loadRadioRecordError) {
			setOffset(null);
		}

		removePreloader(loadRadioRecordCalled);
	}, [loadRadioRecordLoading]);

	useEffect(() => {
		if (loadMoreCalled && !loadMoreError && !loadMoreLoading) {
			setRadioRecords([...radioRecords, ...get(loadMoreData, "v_media_radio_records", [])]);
			setOffset(get(loadMoreData, "v_media_radio_records", []).length < limit ? null : offset + limit);
		} else if (loadMoreError) {
			setOffset(null);
		}

		removePreloader(loadMoreCalled);
	}, [loadMoreLoading]);

	const page_title_full = get(radioRecords, "[0]page_title_full", "");
	const page_title_short = get(radioRecords, "[0]page_title_short", "");
	const main_image_preview = get(radioRecords, "[0]main_image_preview", {});
	const main_image = get(radioRecords, "[0]main_image", {});
	// const updated_at = get(radioRecords, "updated_at", new Date());
	// const created_at = get(radioRecords, "created_at", new Date());
	const imageSrc = get(main_image_preview, "src", get(main_image, "src", ""));
	const url = "/media/radio-records";

	return (
		<Pages
			seo={{}}
			url={url}
		>
			<div itemScope itemType="https://schema.org/AudioObject" className={"radioRecords"}>
				<ContentBlock key={"breadcrumbs"}>
					<div className="container audioguides__breadcrumbs-search">
						<Breadcrumbs
							currentLabel={"Радиоэфиры"}
							pageContext={
								{
									item: {
										title_full: "Медиатека",
										slug: "media",
									},
								}
							}
							classNames={"audioguides__breadcrumbs"}
						/>
					</div>
				</ContentBlock>
				{radioRecords.length ?
					<ContentBlock key={"contents"}>
						<div className={"container audioguides__list"}>
							<List
								itemLayout={"vertical"}
								size={"large"}
								dataSource={radioRecords}
								className={`audioguides${theme.color.key}`}
								renderItem={item => {
									const src = get(item, "main_image_preview.src", "");
									const teaser = get(item, "teaser", "");
									const title_full = get(item, "title_full", "");

									return (
										<List.Item
											key={title_full}
											extra={
												<div className={"audioguides__img"}>
													{
														src && (
															<img
																width={"100%"}
																height={"100%"}
																alt={title_full}
																src={src}
																style={{
																	objectFit: "cover",
																}}
															/>
														)
													}
												</div>
											}
										>
											<List.Item.Meta
												title={
													<span style={removeEmptyKeys({
														fontFamily: theme.fontFamily,
														fontSize: theme.fontSize.text,
														lineHeight: theme.lineHeight.text,
														color: theme.color.text,
														backgroundColor: theme.color.body,
													})}>
														{get(item, "title_full", get(item, "page_title_short", ""))}
													</span>
												}
												description={
													<AudioPlayer url={get(item, "audio.src", "")} slug={get(item, "slug", "")} format={"HH:mm:ss"} />
												}
											/>
											<div
												className={"mb-3"}
												style={removeEmptyKeys({
													fontFamily: theme.fontFamily,
													fontSize: theme.fontSize.text,
													lineHeight: theme.lineHeight.text,
													color: theme.color.text,
													backgroundColor: theme.color.body,
												})}
											>
												{teaser}
											</div>

											<div className={"audioguides__btns"}>
												<Link to={makeUrl.radioRecord(item)}>
													<Button
														className={"col-12"}
														style={removeEmptyKeys({
															fontFamily: theme.fontFamily,
															fontSize: theme.fontSize.text,
															lineHeight: theme.lineHeight.text,
															color: theme.color.body,
															backgroundColor: theme.color.text,
														})}
													>
														Открыть описание
													</Button>
												</Link>
											</div>
										</List.Item>
									);
								}
								}
							/>
						</div>
						<div className="d-flex justify-content-center pb-5">
							<Button
								type={"primary"}
								disabled={loadMoreLoading || !offset || isAllRecordsLoaded}
								loading={loadMoreLoading}
								onClick={() => loadMore({
									variables: {
										offset,
									},
								})}
								style={removeEmptyKeys({
									fontFamily: theme.fontFamily,
									fontSize: theme.fontSize.text,
									lineHeight: theme.lineHeight.text,
									color: theme.color.body,
									backgroundColor: theme.color.text,
								})}
							>
								{showMore}
							</Button>
						</div>
					</ContentBlock> :
					<EmptyData/>
				}
				<div className={"container"}>
					<Share
						url={url}
						pageTitleShort={page_title_short}
						pageTitleFull={page_title_full}
						imageSrc={imageSrc}
					/>
				</div>
			</div>
		</Pages>

	);
}

RedioRecords.propTypes = {
	data: PropTypes.object,
	pageContext: PropTypes.object,
};

RedioRecords.defaultProps = {
	data: {},
	pageContext: {},
};
